import React from 'react';
import Loadable from 'react-loadable';
import ReactDOM from 'react-dom';

export const renderCountryPageModule = () => {
    let containerList : any;
    containerList = document.querySelectorAll('.country-section');
    const containerEl = document.querySelector('#country-module');
    const cpLabels = document.querySelector('#country-page-labels');
    if (!containerList || !containerEl || !cpLabels) return;
    renderModule(containerList, containerEl, cpLabels);
    // [...containerList].forEach(container => renderModule(container));
};

const prepareLabels = labels => {
    const allLabels = labels.replace('{', '').replace('}', '').split(',').reduce((acc, val) => {
        const [prop, value] = val.split('=');
        acc[prop.replaceAll('"', '').trim()] = value.trim();
        return acc;
    }, {});
    return allLabels;
};

const renderModule = (containers, pageElement, labelsElement) => {
    if (!containers || !pageElement || !labelsElement) return;
    const dataSet = [];
    const labels = prepareLabels(labelsElement.dataset.labels);
    [...containers].forEach(container => {
        const { title = '', markets } = container.dataset;
        const dataCountries = JSON.parse(markets || {});
        dataSet.push({ title, countries: dataCountries });
    });

    const { urls } = pageElement.dataset;
    dataSet.push({ urls });

    const CountryPageModule = Loadable({
        loader: () => import(/* webpackChunkName: 'CountryPageModuleChunk' */ './index'),
        loading: () => <div />
    });

    ReactDOM.render(
        <CountryPageModule data={dataSet} labels={labels} />,
        pageElement
    );
};